import { ModalProps, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { ModalHeader, ModalBody, Input, Button, ModalFooter, Label } from 'reactstrap'
import { userService } from 'services/userService'
import { StrapiError } from 'types/Errors'
import { emailUserUpdate } from 'types/User'
import { notifyError, notifySuccess } from 'utils/alertUtils'

import { usePermissions } from '../../../utils/hooks/usePermissions'

import Modal from '../../atoms/modal/Modal'

interface ModalEditEmailProps extends ModalProps {
  isOpen: boolean
  toggle: () => void
  emailUser: string
  updateByAdmin?: (mail: string) => void
}

export const ModalEditEmail: FC<ModalEditEmailProps> = (props) => {
  const { IS_SUPER_ADMIN } = usePermissions()
  const [confirmationSent, setConfirmationSent] = useState(false)
  const { emailUser, isOpen, toggle, updateByAdmin } = props
  const { t } = useTranslation()
  const [newEmail, setNewEmail] = useState(emailUser)

  const [error, setError] = useState<boolean>(false)

  const [submitted, setSubmitted] = useState(false)

  function getInput(e: React.FormEvent<HTMLInputElement>) {
    setError(false)
    setNewEmail(e.currentTarget.value)
  }

  useEffect(() => {
    setNewEmail(emailUser)
    setSubmitted(false)
    setError(false)
  }, [isOpen])

  const { mutate } = useMutation((resetData: emailUserUpdate) => userService.updateEmail(resetData), {
    mutationKey: ['me', 'updateEmail'],
    onSuccess: (data) => {
      notifySuccess(t('toastify.success.requestUpdateEmail'))
      toggle()
      if (updateByAdmin && IS_SUPER_ADMIN()) {
        updateByAdmin(data.email)
      }
      setConfirmationSent(false)
    },
    onError: (error: StrapiError) => {
      notifyError(error.data?.message ? t(error.data.message) : t('toastify.errors.update.email'))
      setConfirmationSent(false)
    },
  })

  function isValidEmail(email: string) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    )
  }

  return (
    <Modal size='md' isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{!submitted ? t('emailUpdate.title') : t('emailUpdate.confirmTitle')}</ModalHeader>

      {!submitted && (
        <ModalBody>
          <Typography className='mb-2'>{t('emailUpdate.currentAddress')}</Typography>
          <Input disabled className='mb-4' type='email' placeholder={emailUser} onChange={getInput}></Input>
          <Typography className='mb-2'>{t('emailUpdate.inputNewAddress')}</Typography>
          <Input
            className='mb-2'
            invalid={error || (newEmail == emailUser && error)}
            type='email'
            onChange={getInput}
          />
          <Label>
            {error && emailUser !== newEmail ? t('emailUpdate.invalidAddress') : ''}
            {error && emailUser == newEmail ? t('emailUpdate.sameAddress') : ''}
          </Label>
        </ModalBody>
      )}

      {submitted && (
        <ModalBody>
          {!IS_SUPER_ADMIN() && <Typography>{t('emailUpdate.askingConfirmationText')}</Typography>}
          {IS_SUPER_ADMIN() && <Typography>{t('emailUpdate.askingConfirmationTextAdminRole')}</Typography>}
          <Typography className='text-secondary text-center my-4'>{newEmail}</Typography>
        </ModalBody>
      )}

      <ModalFooter>
        <Button
          disabled={confirmationSent}
          color='primary'
          onClick={() => {
            if (!submitted) {
              if (!isValidEmail(newEmail) || newEmail == emailUser) {
                setError(true)
              } else {
                setSubmitted(true)
              }
            } else {
              setConfirmationSent(true)
              mutate({
                updateEmailValue: newEmail,
                formerEmailValue: emailUser,
              })
            }
          }}
        >
          {t('common.confirm')}
        </Button>
        <Button
          color='secondary'
          onClick={() => {
            if (!submitted) {
              toggle()
            } else {
              setSubmitted(false)
              setNewEmail(emailUser)
            }
          }}
        >
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalEditEmail
