import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/atoms/button/Button'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { CardBody, CardHeader, CardTitle, Col, Form, ModalBody, ModalHeader } from 'reactstrap'
import { userService } from 'services/userService'
import { notifyError, notifySuccess } from 'utils/alertUtils'
import { userProfileValidationSchema } from 'utils/constants/User'
import { isDelegate, isMember, usePermissions } from 'utils/hooks/usePermissions'

import { formatFirstname } from '../../../../utils/userUtils'

import { User } from '../../../../types/User'

import Card from '../../../atoms/card/Card'
import UserName from '../../../atoms/custom/UserName'
import Row from '../../../atoms/layout/Row'
import Modal from '../../../atoms/modal/Modal'
import Mandates from '../../mandate/Mandates'
import AddressForm from '../../profile/AddressForm'
import ProfilePicture from '../../profile/ProfilePicture'
import UserForm from '../../profile/UserForm'
import ModalEditEmail from '../ModalEditEmail'
import './ModalContact.scss'

interface ModalContactProps {
  isOpen: boolean
  toggle: () => void
  contact?: User
  editRight: boolean
  forceEditRight?: boolean // used to make the distinction between opening from user management and others pages since editRight is override...
}

const ModalContact: FC<ModalContactProps> = ({
  isOpen,
  toggle,
  contact,
  editRight,
  forceEditRight,
}: ModalContactProps) => {
  const [contactState, setContactState] = useState(contact)

  const [isInEditMode, setIsInEditMode] = useState(false)
  const { CONTACTS_CAN_EDIT } = usePermissions()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { register, handleSubmit, errors, reset, watch } = useForm<User>({
    mode: 'onChange',
    resolver: yupResolver(userProfileValidationSchema),
    defaultValues: contact,
  })

  const canEditContact = CONTACTS_CAN_EDIT(contact)
  const formValues = watch()
  useEffect(() => {
    reset(contact)
    setContactState(contact)
    setIsInEditMode(false)
  }, [contact])

  const { mutate } = useMutation(
    ({ userData, userId }: { userData: User; userId: number }) =>
      userService.updateOne(userId, {
        ...userData,
        username: userData.email,
      }),
    {
      mutationKey: ['user', 'updateOne', contact?.id],
      onSuccess: (userData: User) => {
        notifySuccess(t('toastify.success.updateMe'))
        reset(userData)
        setContactState(userData)
        queryClient.refetchQueries(['users', 'getContactsWithFilters'])
        setIsInEditMode(() => false)
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const onSubmit = (rawData: User) => {
    contact &&
      mutate({
        userData: { ...rawData, firstname: formatFirstname(rawData.firstname) },
        userId: contact.id,
      })
  }

  const handleEdit = () => {
    if (isInEditMode) reset(contact)
    setIsInEditMode(!isInEditMode)
  }

  const [showEmailEditModal, setEmailEditModal] = useState(false)
  const toggleEmailEditModal = () => setEmailEditModal((state: boolean) => !state)
  const updateByAdmin = (updatedEmail: string) => {
    if (contact) {
      contact.email = updatedEmail
      reset(contact)
      setContactState(contact)
      queryClient.refetchQueries(['users', 'getContactsWithFilters'])
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>
        {contactState && (
          <span className='d-flex'>
            {`${t('nav.profile')} ${t('common.of')}\u00A0`} <UserName user={contactState} />
          </span>
        )}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row grid className='ModalContactHeader' alignItems='center'>
            <Col xs='12' md='auto'>
              {contact && <ProfilePicture user={contact} hideProfilePicture={contact.hide_profile_picture} />}
            </Col>
            <Col x='12' md='auto'>
              <h2 className='h2 text-primary font-weight-bold'>{contactState && <UserName user={contactState} />}</h2>
            </Col>
          </Row>
          <Row grid className='ModalContactContent'>
            <Col sm={12} lg={8} grid>
              <Card boxShadow='none' color='mediumgrey'>
                <CardHeader>
                  <CardTitle>{t('common.infos')}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs='12'>
                      <ModalEditEmail
                        emailUser={contact?.email || ''}
                        isOpen={showEmailEditModal}
                        toggle={toggleEmailEditModal}
                        updateByAdmin={updateByAdmin}
                      />
                      <UserForm
                        toggleModal={toggleEmailEditModal}
                        errors={errors}
                        isInEditMode={isInEditMode}
                        register={register}
                        isMember={isMember(contact?.members)}
                        isDelegate={isDelegate(contact?.delegates)}
                        canEditUser={true}
                        selectedCollege={formValues.college}
                        defaultOrganization={contact?.organization}
                        defaultOrganizationGroup={contact?.organization_group}
                        hideTelephoneInContacts={contact?.hide_telephone}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12'>
                      {(!contact?.hide_address || canEditContact) && (
                        <AddressForm register={register} isInEditMode={isInEditMode} errors={errors.address} />
                      )}
                    </Col>
                  </Row>
                  {contact && editRight && (forceEditRight || canEditContact) && (
                    <Row className='mt-5'>
                      <Button
                        className='mr-4 ml-4'
                        outline
                        label={isInEditMode ? t('common.cancel') : t('common.edit')}
                        onClick={handleEdit}
                      />
                      <Button label={t('common.save')} className={`${isInEditMode ? '' : 'invisible'}`} />
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} lg={4}>
              {contact && (
                <Mandates
                  title={isDelegate(contact.delegates) ? t('common.meetingsDelegate') : t('common.mandates')}
                  user={contact}
                  editRight={editRight}
                />
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalContact
