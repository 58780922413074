import { yupResolver } from '@hookform/resolvers/yup'
import Card from 'components/atoms/card/Card'
import CustomInput from 'components/atoms/input/CustomInput'
import Section from 'components/atoms/layout/Section'
import ModalEditEmail from 'components/molecules/modals/ModalEditEmail'
import EditProfilePictureModal from 'components/molecules/profile/EditProfilePictureModal'
import EditableProfilePicture from 'components/molecules/profile/EditableProfilePicture'
import { useStore } from 'effector-react'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { BreadcrumbItem, CardBody, CardHeader, CardTitle, Col, Form, FormGroup } from 'reactstrap'
import { documentService } from 'services/documentService'
import { StrapiError } from 'types/Errors'
import { isDelegate, isMember, usePermissions } from 'utils/hooks/usePermissions'

import { notifyError, notifySuccess } from '../utils/alertUtils'
import { userProfileValidationSchema } from '../utils/constants/User'
import { useQuery } from '../utils/hooks/useQuery'

import { DocumentTypeEnum } from '../types/Document'
import { User } from '../types/User'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Button from '../components/atoms/button/Button'
import Row from '../components/atoms/layout/Row'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Header from '../components/molecules/heading/Header'
import Mandates from '../components/molecules/mandate/Mandates'
import AddressForm from '../components/molecules/profile/AddressForm'
import UserForm from '../components/molecules/profile/UserForm'

import { loginService } from '../services/loginService'
import { userService } from '../services/userService'
import { setUser, UserStore } from '../store/UserStore'

/**
 * Vue de l'espace personnel où l'utilisateur peut modifier son profil et voir ses mandats
 */
const UserProfileView: FC = () => {
  const { t } = useTranslation()
  const [isInEditMode, setIsInEditMode] = useState(false)
  const [profilePictureModalOpen, setProfilePictureModaleOpen] = useState<boolean>(false)
  const { CONTACTS_CAN_MANAGE } = usePermissions()

  const userStore = useStore(UserStore)
  if (!userStore) return null

  const { data: user } = useQuery<User>({
    queryKey: ['user', 'getMe'],
    queryFn: userService.getMe,
    onSuccess: async (user) => {
      loginService.setConnectedUser(user)
    },
    cacheTime: 0,
  })

  const { register, handleSubmit, errors, reset, watch } = useForm<User>({
    mode: 'onChange',
    resolver: yupResolver(userProfileValidationSchema),
    defaultValues: user,
  })

  const { mutate } = useMutation((userData: User) => userService.updateMe(userData), {
    mutationKey: ['user', 'updateMe'],
    onSuccess: (userData: User) => {
      notifySuccess(t('toastify.success.updateMe'))
      loginService.setConnectedUser(userData)
      setIsInEditMode(() => false)
    },
    onError: (error: StrapiError) => {
      notifyError(error.data?.message ? t(error.data.message) : t('toastify.errors.update.user'))
    },
  })

  const onSubmit = (userData: User) => {
    mutate(userData)
  }

  const handleEdit = () => {
    if (isInEditMode) reset(userStore)
    setIsInEditMode(!isInEditMode)
  }

  const formValues = watch()

  const onEditProfilePicture = () => setProfilePictureModaleOpen(true)

  const { mutate: uploadProfilePicture } = useMutation(
    (profilePicture: File) =>
      documentService.uploadDocuments(
        [profilePicture],
        'user',
        userStore.id,
        'profilePicture',
        DocumentTypeEnum.USER,
        'users-permissions',
      ),
    {
      mutationKey: ['profilePicture', 'upload'],
      onSuccess: ([profilePicture]) => {
        setUser({ ...userStore, profilePicture }) // TODO actually does not update the store
        notifySuccess(t('toastify.success.uploadProfilePicture'))
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
      onSettled: () => setProfilePictureModaleOpen(false),
    },
  )

  const [showEmailEditModal, setEmailEditModal] = useState(false)
  const toggleEmailEditModal = () => setEmailEditModal((state: boolean) => !state)
  const updateByAdmin = (email: string) => {
    if (userStore) {
      setUser({ ...userStore, email: email }) // TODO actually does not update the store
    }
  }

  return (
    <>
      <View>
        <ViewHead>
          <Section fluid>
            <Header>
              <Breadcrumb>
                <BreadcrumbItem>{t('nav.espacePerso')}</BreadcrumbItem>
              </Breadcrumb>
            </Header>
          </Section>
        </ViewHead>
        <ViewBody>
          <Section fluid>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row grid>
                <Col sm={12} lg={8}>
                  <Card className='Mandates' boxShadow='none' color='mediumgrey'>
                    <CardHeader>
                      <CardTitle>{t('common.infos')}</CardTitle>
                      <EditableProfilePicture user={userStore} onEditProfilePicture={onEditProfilePicture} />
                    </CardHeader>
                    <CardBody>
                      <Row grid flexDirection='column'>
                        <ModalEditEmail
                          emailUser={userStore.email}
                          isOpen={showEmailEditModal}
                          toggle={toggleEmailEditModal}
                          updateByAdmin={updateByAdmin}
                        />
                        <Col xs='auto'>
                          <UserForm
                            toggleModal={toggleEmailEditModal}
                            errors={errors}
                            isInEditMode={isInEditMode}
                            register={register}
                            isMember={isMember(userStore.members)}
                            isDelegate={isDelegate(userStore.delegates)}
                            defaultOrganization={userStore.organization}
                            selectedCollege={formValues.college}
                            isOnProfileView={true}
                            canEditUser={true}
                            defaultOrganizationGroup={userStore.organization_group}
                          />
                        </Col>
                        <Col xs='auto'>
                          <AddressForm
                            name='address'
                            register={register}
                            isInEditMode={isInEditMode}
                            errors={errors.address}
                          />
                          <CustomInput
                            className='mt-3'
                            disabled={!isInEditMode}
                            type='checkbox'
                            id='hide_address'
                            name='hide_address'
                            innerRef={register}
                            label={t('forms.hideAddress')}
                          />
                        </Col>
                        <Col xs='auto' className='justify-content-end'>
                          <FormGroup className='d-flex flex-row-reverse '>
                            <Button
                              className='ml-3'
                              outline={!isInEditMode}
                              color={isInEditMode ? 'secondary' : 'primary'}
                              label={isInEditMode ? t('common.cancel') : t('common.edit')}
                              onClick={handleEdit}
                            />
                            <Button label={t('common.save')} className={`${isInEditMode ? '' : 'invisible'}`} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} lg={4}>
                  <Mandates
                    user={userStore}
                    title={isDelegate(userStore.delegates) ? t('common.myMeetingsDelegate') : t('common.myMandates')}
                    editRight={CONTACTS_CAN_MANAGE()}
                  />
                </Col>
              </Row>
            </Form>
          </Section>
        </ViewBody>
      </View>
      {profilePictureModalOpen && (
        <EditProfilePictureModal
          isOpen={true}
          toggle={() => setProfilePictureModaleOpen(false)}
          uploadProfilePicture={uploadProfilePicture}
        />
      )}
    </>
  )
}

export default UserProfileView
